import React from "react";
import { useRouter } from "next/router";
import { CommonAppContext } from "@bay1/data";

const Landing = (): JSX.Element => {
  const router = useRouter();
  const { activeOrganization, localStore } = React.useContext(CommonAppContext);
  const referrer = localStore?.getRedirectURL() ?? "";

  React.useEffect(() => {
    if (referrer.length > 0) {
      localStore?.setRedirectURL("");
      // eslint-disable-next-line fp/no-mutation
      window.location.href = decodeURIComponent(referrer);

      void router.push(`/organizations/${activeOrganization?.id}/home`);
    }
  }, [activeOrganization, router, referrer, localStore]);

  return <></>;
};

export default Landing;
